export const CONTROL_OBJECT = { controls: null };
export const DEFAULT_CHAR_POS = -17;
export const ALPACA_LINK = "https://maasasia.com";
export const AUTOCRYPT_LINK = "https://autocrypt.io/";
export const BLUESIGNAL_LINK = "http://www.bluesignal.ai/";
export const DRIVERY_LINK = "https://www.thedrivery.com/"
export const KLIO_LINK = "https://www.kliodesign.com/";
export const NOTA_LINK = "https://www.nota.ai/";
export const SMK_LINK = "https://s-mkorea.com/";
export const VEECLE_LINK = "https://www.veecle.io/";
export const ELOREAN_PROJECT_LINK = "https://www.thedrivery.com/events/the-e-lorean-project";
