import { useEffect, useRef } from "react";
import { CustomCursorStyled, CustomCursor2Styled } from "./CustomCursorStyled";

export const CustomCursor = () => {
    const cursorRef = useRef();
    const cursor2Ref = useRef();

    const handleMouseMove = (e) => {
        cursorRef.current.style.top = `${e.clientY}px`;
        cursorRef.current.style.left = `${e.clientX}px`;
        cursor2Ref.current.style.top = `${e.clientY}px`;
        cursor2Ref.current.style.left = `${e.clientX}px`;
    };
    const handleMouseDown = () => {
        cursorRef.current.style.transform = "translate(-50%, -50%) scale(2)";
    };
    const handleMouseUp = () => {
        cursorRef.current.style.transform = "translate(-50%, -50%) scale(1)";
    };

    useEffect(() => {
        window.addEventListener("mousemove", handleMouseMove);
        window.addEventListener("mouseup", handleMouseUp);
        window.addEventListener("mousedown", handleMouseDown);
        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
            window.removeEventListener("mouseup", handleMouseUp);
            window.removeEventListener("mousedown", handleMouseDown);
        };
    }, []);

    return (
        <>
            <CustomCursorStyled ref={cursorRef} />
            <CustomCursor2Styled ref={cursor2Ref} id="custom-cursor-2" />
        </>
    );
};
