import { lazy, startTransition } from "react";
import useStore from "../../utils/store";
import DebugParams from "../Debug/DebugParams";
import {
    InstructionsWrapper,
    InstructionsContainer,
} from "./InstructionsStyled";

const InstructionsMobile = lazy(() => import("./InstructionsMobile"));
const InstructionsPC = lazy(() => import("./InstructionsPC"));

const Instructions = () => {
    const stage = useStore((state) => state.stage);
    const setStage = useStore((state) => state.setStage);

    const { miscParams } = DebugParams();
    const { mobile } = miscParams;

    const handlePointerDown = (e) => {
        if (stage === 1) e.stopPropagation();
    };

    const handleOnClick = () => {
        if (stage === 1) {
            startTransition(() => {
                setStage(2);
            });
        }
    };

    return (
        <InstructionsWrapper
            onPointerDown={handlePointerDown}
            onClick={handleOnClick}
            visible={stage === 1}
        >
            <InstructionsContainer>
                {mobile ? <InstructionsMobile /> : <InstructionsPC />}
            </InstructionsContainer>
        </InstructionsWrapper>
    );
};

export default Instructions;
