import { lazy, Suspense, useState } from "react";
import { AdaptiveDpr, Preload } from "@react-three/drei";
import { Perf } from "r3f-perf";
import { Canvas } from "@react-three/fiber";

import Controls from "./components/Controls/Controls";
import { DEFAULT_CHAR_POS } from "./utils/constants";
import { isMobile } from "./utils/functions";

const Scene = lazy(() => import("./components/Scene"));

const App = () => {
    const [debugMode] = useState(window.location.hash.includes("debug"));

    return (
        <>
            <Canvas
                shadows
                camera={{
                    fov: isMobile()
                        ? 80
                        : Math.max(
                            Math.min(
                                45 * (16 / 9 / (window.innerWidth / window.innerHeight)),
                                70
                            ),
                            30
                        ),
                    near: 0.01,
                    far: 100,
                    position: [0, 1.5, DEFAULT_CHAR_POS - 0.01],
                    rotation: [-Math.PI * 0.01, Math.PI, 0],
                }}
                performance={{
                    current: 1,
                    min: 0.1,
                    max: 1,
                    debounce: 200,
                }}
                dpr={[1, 2]}
            >
                {debugMode && <Perf position="top-left" />}

                <Suspense fallback={null}>
                    <Controls />
                    <Scene />
                    <Preload all />
                    <AdaptiveDpr pixelated />
                </Suspense>
            </Canvas>
        </>
    );
};

export default App;
