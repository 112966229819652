import { create } from "zustand";
import { isMobile } from "./functions";

const useStore = create((set) => ({
    mobile: isMobile(),
    stage: 0,
    setStage: () => set((state) => ({ stage: state.stage + 1 })),
}));

export default useStore;
