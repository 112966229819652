import { useEffect } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import { OrbitControls } from "three/addons/controls/OrbitControls.js";

import { CONTROL_OBJECT, DEFAULT_CHAR_POS } from "../../utils/constants";
import { isMobile } from "../../utils/functions";

import DebugParams from "../Debug/DebugParams";

const Controls = () => {
    const camera = useThree((state) => state.camera);
    const gl = useThree((state) => state.gl);
    const size = useThree((state) => state.size);

    const mobile = DebugParams().miscParams.mobile;

    // update camera fov to be wider on narrow screens
    useEffect(() => {
        if (!mobile) {
            camera.aspect = size.width / size.height;
            camera.fov = Math.max(
                Math.min(45 * (16 / 9 / (size.width / size.height)), 70),
                45
            );
            camera.updateProjectionMatrix();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [camera, size]);

    useEffect(() => {
        CONTROL_OBJECT.controls = new OrbitControls(camera, gl.domElement);
        CONTROL_OBJECT.controls.name = "OrbitControls";
        CONTROL_OBJECT.controls.minDistance = 0.01;
        CONTROL_OBJECT.controls.maxDistance = 1.01;
        CONTROL_OBJECT.controls.rotateSpeed = isMobile() ? 0.7 : 0.4;
        CONTROL_OBJECT.controls.enableDamping = !isMobile();
        CONTROL_OBJECT.controls.enablePan = false;
        CONTROL_OBJECT.controls.enableZoom = false;
        CONTROL_OBJECT.controls.minPolarAngle = Math.PI / 7;
        CONTROL_OBJECT.controls.maxPolarAngle = Math.PI / 1.6; // prevent going below the ground
        CONTROL_OBJECT.controls.target.set(0, 1.5, DEFAULT_CHAR_POS);

        return () => {
            CONTROL_OBJECT.controls?.dispose();
            CONTROL_OBJECT.controls = null;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useFrame(() => {
        if (!CONTROL_OBJECT.controls) return;
        CONTROL_OBJECT.controls.update();
    });
};

export default Controls;
