import styled from "styled-components";
import { Adler_Logo_L, DriveryKR_LOGO } from "../../assets/images";

const fakeLoadingTime = 4; // seconds

export const StartScreenWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fcfcfccc;
  backdrop-filter: blur(5px);
  font-weight: 700;
  color: #000;
  z-index: 1000;
  user-select: none;
  cursor: default;

  display: ${({ visible }) => (visible ? "block" : "none")};

  /* Loading bar animation */
  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: #e8367c;
    transform-origin: left;

    /* Run loading bar animations */
    animation: loading ${fakeLoadingTime}s ease-out forwards,
      fade-out-bar 1s ${fakeLoadingTime}s forwards;
    opacity: ${({ visible }) => (visible ? "1" : "0")};
  }

  @keyframes loading {
    0% {
      transform: scaleX(0);
    }
    100% {
      transform: scaleX(1);
    }
  }

  @keyframes fade-out-bar {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

export const StartScreenContent = styled.div`
  animation: fade-in 1s ${fakeLoadingTime}s forwards;

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const StartScreenContainer = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 2rem;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  opacity: 0;
  animation: fade-in-container 1s ${fakeLoadingTime}s forwards;

  @keyframes fade-in-container {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const AdlerLogoStyled = styled.img.attrs({
  src: Adler_Logo_L,
})`
  width: 25%;
  min-width: 10rem;
  height: auto;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  
  @media (max-width: 768px) {
    width: 22%;
  }
`;

export const DriveryLogoStyled = styled.img.attrs({
  src: DriveryKR_LOGO,
})`
  width: 17%;
  max-width: 36rem;
  min-width: 7rem;
  height: auto;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  
  @media (max-width: 768px) {
    width: 15%;
  }
`;

export const StartScreenPlus = styled.p`
  font-size: 4rem;
  margin: 1rem 0;
  color: #e8367c;
  @media (max-width: 768px) {
    font-size: 3rem;
  }

  @media (max-width: 480px) {
    font-size: 2rem;
  }
`;

export const StartScreenTitle = styled.p`
  font-size: 3rem;
  margin: 3rem 0;
  @media (max-width: 768px) {
    font-size: 2.2rem;
  }

  @media (max-width: 480px) {
    font-size: 1.8rem;
  }
`;

export const StartScreenButton = styled.button`
  font-family: "Ubuntu", sans-serif;
  font-size: 2.25rem;
  font-weight: 700;
  padding: 1.4rem 2rem;
  border-radius: 4rem;
  border: none;
  background-color: #e8367c;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.2s linear;

  &:hover {
    transform: scale(1.2);
  }

  @media (max-width: 768px) {
    font-size: 1.25rem;
    padding: 1rem 1.6rem;
  }

  @media (max-width: 480px) {
    font-size: 1rem;
  }
`;
