import styled from "styled-components";
import { ReactComponent as AdlerShift } from "../../assets/icons/adler_guide_shift.svg";
import { ReactComponent as AdlerWASD } from "../../assets/icons/adler_guide_WASD.svg";

export const InstructionsWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #1d1d1b77;
  font-weight: 700;
  color: #e8367c;
  pointer-events: all;
  user-select: none;
  z-index: 500;

  display: ${({ visible }) => (visible ? "block" : "none")};
`;

export const InstructionsContainer = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 65%;
  height: 50%;
  background: #ffffff;
  border-radius: 1rem;
  padding: 2rem;
`;

export const InstructionsColumn = styled.div`
  flex: 1;
  padding: 20px;

  /* center content */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  position: relative;

  /* Vertical lines for middle column */
  &:nth-child(2)::before,
  &:nth-child(2)::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 0.2rem;
    background-color: #e8367c;
  }

  &:nth-child(2)::before {
    left: -5px;
    height: 40%; /* Adjust the percentage as needed */
  }

  &:nth-child(2)::after {
    right: -5px;
    height: 40%; /* Adjust the percentage as needed */
  }
`;

export const InstructionImageContainer = styled.div`
  width: 100%;
  min-height: 15rem;
  height: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AdlerWASDStyled = styled(AdlerWASD)`
  width: 80%;
  height: auto;
`;
export const AdlerShiftStyled = styled(AdlerShift)`
  width: 70%;
  min-height: 12rem;
  height: auto;
`;
export const AdlerMouseStyled = styled.img`
  width: 40%;
  height: auto;

  /* disable all interactions */
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  /* disable dragging */
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
`;

export const InstructionsText = styled.p`
  /* position: absolute;
  bottom: 3rem; */
  font-size: 2.75rem;

  @media (max-width: 768px) {
    font-size: 2rem;
  }

  @media (max-width: 480px) {
    font-size: 1.5rem;
  }
`;

export const InstructionsMobileRow = styled.div`
  display: block;
  align-items: center;
  justify-content: center;
  height: 50%;
  width: 100%;
`;

export const InstructionsMobileSeparator = styled.div`
  width: 100%;
  height: 0.2rem;
  margin: 1rem 0;
  background-color: #e8367c;
`;

export const InstructionsMobileContentWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr auto 1fr;
  width: 100%;

  /* center content */
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const InstructionsMobileRowWrapper = styled.div`
  /* center content */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const InstructionsMobileSvg = styled.svg`
  height: 5.5rem;
  width: 5.5rem;
  margin: 0;

  @media (max-width: 768px) {
    height: 5rem;
    width: 5rem;
  }

  @media (max-width: 480px) {
    height: 4rem;
    width: 4rem;
  }
`;

export const InstructionsMobileText = styled.p`
  font-size: 2.5rem;
  margin: 0.7rem 0;
`;
