import './index.css';
import ReactDOM from 'react-dom/client';
import App from './App.jsx';
import { StrictMode } from 'react';
import OverlayElements from './components/OverlayElements';

const root = ReactDOM.createRoot(document.querySelector('#root'));

root.render(
    <StrictMode>
        <OverlayElements />
        <App />
    </StrictMode>
);
