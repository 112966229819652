import { memo, startTransition } from "react";
import useStore from "../../utils/store";
import {
    AdlerLogoStyled,
    DriveryLogoStyled,
    StartScreenButton,
    StartScreenContainer,
    StartScreenContent,
    StartScreenPlus,
    StartScreenTitle,
    StartScreenWrapper,
} from "./StartScreenStyled";

const StartScreen = () => {
    const stage = useStore((state) => state.stage);
    const setStage = useStore((state) => state.setStage);

    const handlePointerDown = (e) => {
        if (stage === 0) e.stopPropagation();
    };

    const handleOnClick = (e) => {
        if (stage === 0) {
            e.stopPropagation();
            startTransition(() => {
                setStage(1);
            });
        }
    };

    return (
        <StartScreenWrapper
            onClick={handlePointerDown}
            onContextMenu={(e) => e.preventDefault()}
            onPointerDown={handlePointerDown}
            visible={stage === 0}
        >
            <StartScreenContent>
                <StartScreenContainer>
                    <AdlerLogoStyled title="AdlerLogo" />
                    <StartScreenPlus>+</StartScreenPlus>
                    <DriveryLogoStyled title="DriveryLogo" />
                    <StartScreenTitle>The Drivery Showcase</StartScreenTitle>
                    <StartScreenButton onClick={handleOnClick}>
                        START EXPLORING
                    </StartScreenButton>
                </StartScreenContainer>
            </StartScreenContent>
        </StartScreenWrapper>
    );
};

export default memo(StartScreen);
