import { lazy } from "react";
import { useProgress } from "@react-three/drei";

import Instructions from "./Instructions/Instructions";
import StartScreen from "./StartScreen/StartScreen";
import DebugParams from "./Debug/DebugParams";
import useStore from "../utils/store";
import { CustomCursor } from "./CustomCursor/CustomCursor";

const Branding = lazy(() => import("./Branding/Branding"));
const DebugMenu = lazy(() => import("./Debug/DebugMenu"));
const Joystick = lazy(() => import("./Controls/Joystick"));
const Loading = lazy(() => import("./Loading/Loading"));
const VideoPlayer = lazy(() =>
    import("./VideoPlayerOverlay/VideoPlayer")
);
const ShowcaseItemOverlay = lazy(() =>
    import("./ShowcaseItemOverlay/ShowcaseItemOverlay")
);

export default function OverlayElements() {
    const { miscParams } = DebugParams();
    const { mobile } = miscParams;

    const stage = useStore((state) => state.stage);
    const progress = useProgress((state) => state.progress);

    return (
        <>
            {!mobile && <CustomCursor />}
            <StartScreen />
            <Instructions />

            <Branding isMobile={mobile} />

            {progress < 100 && <Loading isMobile={mobile} visible={stage === 2} />}

            <DebugMenu />
            <Joystick />
            <VideoPlayer />
            <ShowcaseItemOverlay />
        </>
    );
}
