export const handleMouseHover = (isHovering) => {
    const cursor2 = document.getElementById("custom-cursor-2");
    if (!cursor2) return;

    if (isHovering) {
        cursor2.style.backgroundColor = "rgba(255, 0, 0, 0.5)";
    } else {
        cursor2.style.backgroundColor = "rgba(255, 255, 255, 0.5)";
    }
};

export const isMobile = () =>
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
    );
