import styled from "styled-components";

export const CustomCursorStyled = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    border: 1px solid black;
    pointer-events: none;
    z-index: 9999;
    transform: translate(-50%, -50%);
    transition: transform 0.1s ease-out;
`;

export const CustomCursor2Styled = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.5);
    border: 1px solid black;
    pointer-events: none;
    z-index: 9998;
    transform: translate(-50%, -50%);
`;
