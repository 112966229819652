import { useControls } from "leva";

export default function DebugParams(debugMode = window.location.hash.includes("debug")) {
    const isMobile = () => {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
        );
    };

    const miscParams = useControls("Miscellaneous", {
        mobile: {
            value: isMobile(),
            label: "Mobile",
        },
        thirdperson: isMobile(),
    });
    const physicsParams = useControls("Physics", {
        enableBounds: {
            value: true,
            label: "Enable Bounds",
        },
        showBounds: {
            value: debugMode,
            label: "Show Bounds",
        },
    });
    const envParams = useControls("Environment Map", {
        background: {
            value: true,
        },
        intensity: {
            value: 0.2,
            min: 0,
            max: 1,
            step: 0.01,
            label: "Intensity",
        },
    });
    const crosshairParams = useControls("Crosshair", {
        size: {
            value: 1,
            min: 1,
            max: 10,
            step: 1,
        },
        color: { value: "#ffffff" },
        enable: miscParams.thirdperson ? false : true,
    });
    const lightParams = useControls("Lighting", {
        shadows: true,
        helpers: false,
        ambientLightIntensity: {
            value: 0.3,
            min: 0,
            max: 1,
            step: 0.01,
        },
        spolightIntensity: {
            value: 2.0,
            min: 0,
            max: 10,
            step: 0.1,
        },
        pointLightIntensity: {
            value: 0.1,
            min: 0,
            max: 1,
            step: 0.01,
        },
    });
    // const factoryParams = useControls("Factory", {
    //     atom: {
    //         value: true,
    //         label: "Show Atom",
    //     },
    //     logos: {
    //         value: true,
    //         label: "Show Logos",
    //     },
    //     vehicles: {
    //         value: true,
    //         label: "Show Vehicles",
    //     },
    //     tvs: {
    //         value: true,
    //         label: "Show TVs",
    //     },
    // });

    // const effectParams = useControls('Post-processing Effect', {
    //     brightness: {
    //         value: 0,
    //         min: -1,
    //         max: 1,
    //         step: 0.01
    //     },
    //     contrast: {
    //         value: -0.15,
    //         min: -1,
    //         max: 1,
    //         step: 0.01
    //     }
    // });

    return {
        miscParams,
        physicsParams,
        envParams,
        crosshairParams,
        lightParams,
        // factoryParams,
        // effectParams,
    };
}
